export default {
    title: 'Недвижимость',
    realtyType: {
        title: 'Тип',
        placeholder: '-',
    },
    section: {
        title: 'Секция',
        placeholder: '-',
    },
    floor: {
        title: 'Этаж',
        placeholder: '-',
    },
    postalNumber: {
        title: '№ (почт.)',
        placeholder: '-',
    },
    buildingNumber: {
        title: '№ (строит.)',
        placeholder: '-',
    },
};
