import Vue from 'vue';

Vue.directive('focus', {
    inserted(element: HTMLElement, { value }) {
        if (value && typeof value === 'string') {
            element = element.querySelector(value) ?? element;
        }

        element.focus();
    },
});
