import { camelCaseFileName } from '@/utils/Helper';
import { set, merge } from 'lodash';

const messages: any = {};
const configFiles = require.context('./', true, /^(?!.*(index)).*\.ts$/);

configFiles.keys().forEach((path: string) => {
    const matched = path.match(/^\.\/([A-Za-z0-9-_]+)\/(.*)\.(json|ts)$/);
    if (matched && matched.length > 2) {
        const locale = camelCaseFileName(matched[1]); /* get localse en/ru */
        let namespace = matched[2];

        const translations = configFiles(path).default;
        /* init object for current language if empty */
        if (!messages[locale]) {
            messages[locale] = {};
        }

        // +1 для слэша после языка
        if (namespace.startsWith(locale)) namespace = namespace.slice(locale.length + 1);

        namespace = namespace.replace(/\//g, '.');

        if (namespace) set(messages[locale], namespace.split('.').map(camelCaseFileName), translations);
        else
            merge(
                messages[locale],
                translations,
            ); /* Если namespace пуст, записываем переводы прямо в корень объекта для этого языка */
    }
});

export default messages;
