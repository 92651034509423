export default {
    status: 'Статус',
    project: 'Проект',
    type: 'Тип',
    deadlines: 'Сроки',
    violations: 'Замечания',
    violationType: 'Тип схемы',
    prescriptionType: 'Тип предписания',
    addPrescription: 'Добавить предписание',
};
