import { confirmed, email, length, max, min, regex, required, double, digits } from 'vee-validate/dist/rules';

// Interfaces
import { ValidationRule } from 'vee-validate/dist/types/types';
import i18n from '@/plugins/VueI18n';
import IFile from '@/ship/Models/IFile';

// API
import { checkUniqueOrganisationData, checkUserUniqueEmail } from '@/utils/Api';

const filesCount: ValidationRule = {
    validate: (field: IFile[], params): boolean | string => {
        const [minFiles, maxFiles] = params as number[];

        return !((minFiles && field.length < minFiles) || (maxFiles && field.length > maxFiles));
    },
    message: i18n.t('errors.filesCount').toString(),
};

const isUniqueOrganisationINN: ValidationRule = {
    validate: async (field: number): Promise<boolean | string> => {
        const { INN } = await checkUniqueOrganisationData({ INN: field });
        return !!INN;
    },
    message: i18n.t('errors.notUniqueOrganisationINN').toString(),
};

const isUniqueOrganisationPhone: ValidationRule = {
    validate: async (field: string): Promise<boolean | string> => {
        const { phoneNumber } = await checkUniqueOrganisationData({ contact_number: field });
        return !!phoneNumber;
    },
    message: i18n.t('errors.notUniqueOrganisationPhone').toString(),
};

const isUniqueUserEmail: ValidationRule = {
    validate: async (field: string): Promise<boolean | string> => {
        const { email } = await checkUserUniqueEmail(field);
        return !!email;
    },
    message: i18n.t('errors.notUniqueUserEmail').toString(),
};

export {
    isUniqueUserEmail,
    isUniqueOrganisationPhone,
    isUniqueOrganisationINN,
    filesCount,
    confirmed,
    max,
    min,
    regex,
    required,
    email,
    length,
    double,
    digits,
};
