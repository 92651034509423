export default {
    services: 'Сервисы',
    offer: 'Оферта',
    price: 'Прайс',
    report: 'Отчеты',

    // Предписания
    prescriptionHome: 'Объекты',
    prescriptionRequirements: 'Строительные нормы и правила',
    prescriptionScheme: 'Загрузить чертеж',
    prescriptionList: 'Общий журнал',
    prescriptionView: 'Просмотр предписания',
    prescriptionCreate: 'Создание предписания',
    prescriptionAcceptance: 'Приёмка квартир',
    acceptanceView: 'Просмотр предписания',
    acceptanceCreate: 'Создание предписания',
    prescriptionWarranty: 'Гарантия',
    warrantyView: 'Просмотр предписания',
    warrantyCreate: 'Создание предписания',

    // Проектирование;

    designingHomeProjects: 'Проекты',
    designingHomeDrawings: 'Чертежи',
    designingHomeModels: 'Модели',
    designingModel: 'Модель',
    designingHomeTasks: 'Задачи',
    designingPlans: 'Планы',

    // Администрирование
    administration: 'Администрирование',

    administrationUsers: 'Пользователи',
    administrationUsersCreate: 'Создание пользователя',
    administrationUsersEdit: 'Редактирование пользователя',

    administrationReplacement: 'Замещения',
    administrationReplacementCreate: 'Создание замещения',
    administrationReplacementEdit: 'Редактирование замещения',

    administrationProjects: 'Проекты',
    administrationProjectsCreate: 'Создание проекта',
    administrationProjectsEdit: 'Редактирование проекта',

    administrationCities: 'Города',
    administrationCitiesCreate: 'Создание города',
    administrationCitiesEdit: 'Редактирование города',

    administrationOrganisations: 'Организации',
    administrationOrganisationsCreate: 'Создание организации',
    administrationOrganisationsEdit: 'Редактирование организации',

    administrationSections: 'Секции',
    administrationSectionsEdit: 'Редактирование секции',

    administrationFloors: 'Этажи',
    administrationFloorsEdit: 'Редактирование этажа',

    administrationApartments: 'Квартиры',
    administrationApartmentsEdit: 'Редактирование квартиры',

    administrationDesigning: 'Уведомления: Проектирование',

    // Сметы
    estimatesProjects: 'Проекты смет',
    estimatesJournal: 'Журнал смет',
    estimatesListActs: 'Реестр актов',
    estimatesEstimate: 'Смета',
    estimatesAdditional: 'Доп. смета',
    estimatesAct: 'Акт',
    estimatesContract: 'Договор',

    // Project Management
    projectManagementProjects: 'Проекты',
    projectManagementProject: 'Проект',
    projectManagementCharts: 'Графики',
    projectManagementChartsCreate: 'Создание графика',
    projectManagementChartsEdit: 'Редактирование графика',
    projectManagementChart: 'График',
    projectManagementChartBpc: 'Базовый план-график',
    projectManagementChartBpcCreate: 'Создать базовый план-график',
    projectManagementChartDpcQuart: 'Детальный план-график (квартальный ДПГ)',
    projectManagementChartDpcQuartCreate: 'Создать детальный план-график (квартальный ДПГ)',
    projectManagementChartDpcExec: 'Детальный план-график (исполнение ДПГ)',
    projectManagementChartDpcExecCreate: 'Создать детальный план-график (исполнение ДПГ)',
    projectManagementChartDeadlines: 'Сроки графика (план-факт)',
    projectManagementChartWeeklyReport: 'Отчет по строительству (Недельный)',
    projectManagementReports: 'Отчетность',
    projectManagementReportsDocumentsBpc: 'Базовый план-график',
    projectManagementReportDpcExec: 'Отчет по исполнению ДПГ',
    projectManagementReportConstruction: 'Отчет по строительству (месяц/год)',
    projectManagementReportDeadlines: 'Сроки графика (план-факт)',
    projectManagementReportConsolidated: 'Сводный отчет по графикам',
};
