export default {
    dragFilesHere: 'Перетащите файлы сюда',
    orSelectFiles: 'или выберите на компьютере',
    removeFile: 'Удалить файл',
    uploadMore: 'Загрузить еще',
    uploadFiles: 'Загрузить файлы',
    uploadMoreNFiles:
        'Загрузите еще {n} файлов | Загрузите еще {n} файл | Загрузите еще {n} файла | Загрузите еще {n} файлов',
    uploadImage: 'Загрузить изображение',
};
