export default {
    chooseProject: 'Выберите проект',
    chooseSubcontractor: 'Укажите субподрядчика',
    chooseExecutor: 'Выберите исполнителя',
    placeholder: {
        project: 'Выберите проект',
        schemeType: 'Выберите тип схемы',
        section: 'Выберите секцию',
        floor: 'Выберите этаж',
        apartment: 'Выберите квартиру',
    },
    select: 'Нажмите, чтобы выбрать',
    deselect: 'Нажмите, чтобы убрать',
    selected: 'Выбрано',
};
