// Utils
import { keeper } from '@/plugins/PromiseKeeper/PromiseKeeper';

// Api
import {
    createOrganisation,
    getAllApartments,
    getAllBasicRequirement,
    getAllCities,
    getAllFloors,
    getAllOrganisations,
    getAllPositions,
    getAllProjects,
    getAllRoles,
    getAllSections,
    getFloorById,
    getMainPackData,
    getUserProfile,
    getUsers,
    getAllTypesViolations,
    getViolationsAcceptance,
    getViolationsWarranty,
    getViolationStatuses,
    getPMChart,
    updatePMChart,
    getChartVersionStructure,
} from '@/utils/Api';

// Interfaces
import { ActionTree } from 'vuex';
import { RootState } from '@/store/Root/Root';

import User from '@/ship/Models/User';
import Role from '@/ship/Models/Role';
import City from '@/ship/Models/City';
import Floor from '@/ship/Models/Floor';
import Project from '@/ship/Models/Project';
import Position from '@/ship/Models/Position';
import MainPackData from '@/ship/Models/MainPackData';
import ViolationCount from '@/ship/Models/ViolationCount';
import IncludeViolation from '@/ship/Models/IncludeViolation';
import IncludeViolationAcceptance from '@/ship/Models/IncludeViolationAcceptance';
import IncludeViolationWarranty from '@/ship/Models/IncludeViolationWarranty';
import BasicRequirement from '@/ship/Models/BasicRequirement';
import Organisation from '@/ship/Models/Organisation';
import Section from '@/ship/Models/Section';
import Apartment from '@/ship/Models/Apartment';
import { IPMChart } from '@/ship/Models/IProjectManagement';
import { GetRequestParams } from '@/ship/Requests/RequestParams';
import PMChartEditModel from '@/ship/Models/PMChartEditModel';
import { ChartVersion } from '@/ship/Models/ProjectManagement';

export const actions: ActionTree<RootState, RootState> = {
    async GET_ALL_REQUIREMENT({ state, commit }): Promise<BasicRequirement[]> {
        if (state.requirements) {
            return state.requirements;
        }

        const {
            data: { data: requirements },
        } = await getAllBasicRequirement();
        commit('SET_REQUIREMENT', requirements);

        return requirements;
    },

    async GET_USER_PROFILE({ commit }): Promise<User> {
        const {
            data: { data: user },
        } = await getUserProfile();

        commit('SET_USER_PROFILE', user);

        return user;
    },

    async GET_ALL_VIOLATIONS({ commit }, params: { page: number; query: string }): Promise<IncludeViolation[]> {
        const {
            data: {
                data: violations,
                meta: { pagination: pagination },
            },
        } = await getAllTypesViolations(params.page, params.query);
        commit('GET_ALL_VIOLATIONS', violations);
        commit('PAGINATION', pagination);
        return violations;
    },

    async GET_ALL_VIOLATIONS_ACCEPTANCE(
        { commit },
        params: { page: number; query: string },
    ): Promise<IncludeViolationAcceptance[]> {
        const {
            data: {
                data: violations,
                meta: { pagination: pagination },
            },
        } = await getViolationsAcceptance(params.page, params.query);
        commit('GET_ALL_VIOLATIONS_ACCEPTANCE', violations);
        commit('PAGINATION', pagination);
        return violations;
    },

    async GET_ALL_VIOLATIONS_WARRANTY(
        { commit },
        params: { page: number; query: string },
    ): Promise<IncludeViolationWarranty[]> {
        const {
            data: {
                data: violations,
                meta: { pagination: pagination },
            },
        } = await getViolationsWarranty(params.page, params.query);
        const _violations = violations.map((item) => ({
            ...item,
            statusId: item.status,
        }));
        commit('GET_ALL_VIOLATIONS_WARRANTY', _violations);
        commit('PAGINATION', pagination);
        return _violations;
    },

    async GET_FLOOR_BY_ID({ commit }, id: number): Promise<Floor> {
        const {
            data: { data: floor },
        } = await getFloorById(id);
        commit('GET_FLOOR_BY_ID', floor);
        return floor;
    },

    async GET_MAIN_PACK_DATA({ commit, state }, forceUpdate = false): Promise<MainPackData> {
        if (state.mainPackData && !forceUpdate) return state.mainPackData;

        return keeper.get('GET_MAIN_PACK_DATA', async () => {
            const { data: mainPackData } = await getMainPackData();

            commit('SET_MAIN_PACK_DATA', mainPackData);

            return mainPackData;
        });
    },

    async GET_VIOLATION_COUNT({ commit }, data: { cities: number[] }): Promise<ViolationCount> {
        const { data: violationCount } = await getViolationStatuses(data);
        commit('SET_VIOLATION_COUNT', violationCount);
        return violationCount;
    },

    async GET_USERS({ state, commit }, params?: { forceUpdate?: boolean }): Promise<User[]> {
        if (state.users && !params?.forceUpdate) return state.users;

        return keeper.get('GET_USERS', async () => {
            const {
                data: { data: users },
            } = await getUsers({ include: 'projects,roles,permissions', limit: 0 });

            commit('SET_USERS', users);

            return users;
        });
    },

    async GET_ALL_ROLES({ state, commit }): Promise<Role[]> {
        if (state.roles) {
            return state.roles;
        }

        return keeper.get('GET_ALL_ROLES', async () => {
            const {
                data: { data: roles },
            } = await getAllRoles();

            commit('SET_ALL_ROLES', roles);

            return roles;
        });
    },

    async GET_ALL_POSITIONS({ state, commit }): Promise<Position[]> {
        if (state.positions) {
            return state.positions;
        }

        return keeper.get('GET_ALL_POSITIONS', async () => {
            const {
                data: { data: positions },
            } = await getAllPositions();

            commit('SET_ALL_POSITIONS', positions);

            return positions;
        });
    },

    async GET_ALL_PROJECTS({ state, commit }): Promise<Project[]> {
        if (state.projects) return state.projects;

        return keeper.get('GET_ALL_PROJECTS', async () => {
            const {
                data: { data: projects },
            } = await getAllProjects({
                limit: 0,
                include: 'users,generalContractor.users,customer.users,generalDesigners,generalDesignersIds',
            });

            commit('SET_ALL_PROJECTS', projects);

            return projects;
        });
    },

    async GET_ALL_CITIES({ state, commit }): Promise<City[]> {
        if (state.cities) {
            return state.cities;
        }

        return keeper.get('GET_ALL_CITIES', async () => {
            const {
                data: { data: cities },
            } = await getAllCities();

            commit('ADD_TO_ALL_CITIES', cities);

            return cities;
        });
    },

    async GET_ALL_ORGANISATIONS(
        { state, commit },
        params?: { forceUpdate?: boolean; requiredIncludes?: string; limit?: number },
    ): Promise<Organisation[]> {
        if (state.organisations && !params?.forceUpdate) return state.organisations;

        return keeper.get('GET_ALL_ORGANISATIONS', async () => {
            const organisations = (
                await getAllOrganisations({ include: params?.requiredIncludes, limit: params?.limit })
            )?.data?.data;

            commit('SET_ALL_ORGANISATIONS', organisations);

            return organisations;
        });
    },

    async GET_ALL_SECTIONS({ state, commit }): Promise<Section[]> {
        if (state.sections.length) {
            return state.sections;
        }

        return keeper.get('GET_ALL_SECTIONS', async () => {
            const {
                data: { data: sections },
            } = await getAllSections();

            commit('SET_ALL_SECTIONS', sections);

            return sections;
        });
    },

    async GET_ALL_FLOORS({ state, commit }): Promise<Floor[]> {
        if (state.floors) {
            return state.floors;
        }

        return keeper.get('GET_ALL_FLOORS', async () => {
            const {
                data: { data: floors },
            } = await getAllFloors();

            commit('SET_ALL_FLOORS', floors);

            return floors;
        });
    },

    async GET_ALL_APARTMENTS({ state, commit }): Promise<Apartment[]> {
        if (state.apartments) {
            return state.apartments;
        }

        return keeper.get('GET_ALL_APARTMENTS', async () => {
            const {
                data: { data: apartments },
            } = await getAllApartments();

            commit('SET_ALL_APARTMENTS', apartments);

            return apartments;
        });
    },

    async GET_CHART_BY_ID({ state, commit }, payload: { id: number; params?: GetRequestParams }): Promise<IPMChart> {
        if (state.chart && state.chart.id == payload.id) return state.chart;

        return keeper.get('GET_CHART_BY_ID', async () => {
            const {
                data: { data: chart },
            } = await getPMChart(payload.id, payload.params);

            commit('SET_CHART_BY_ID', chart);

            return chart;
        });
    },

    async UPDATE_CHART_BY_ID({ state, commit }, payload: { id: number; data: PMChartEditModel }): Promise<IPMChart> {
        return keeper.get('UPDATE_CHART_BY_ID', async () => {
            const {
                data: { data: chart },
            } = await updatePMChart(payload.id, payload.data);

            commit('UPDATE_CHART_BY_ID', chart);

            return chart;
        });
    },

    async GET_STRUCTURE({ state, commit }, id: number): Promise<ChartVersion> {
        if (state.structure && state.structure.id == id) return state.structure;
        return keeper.get('GET_STRUCTURE', async () => {
            const {
                data: { data: structure },
            } = await getChartVersionStructure(id);

            commit('SET_STRUCTURE', structure);

            return structure;
        });
    },
};
export default actions;
