export default {
    term: 'Срок',
    descriptionCause: 'Описание причины',
    accept: 'Принять',
    reject: 'Отклонить',
    confirm: 'Подтвердить',
    viewTitle: 'Перенос срока',
    createTitle: 'Перенос срока',
    viewToggler: 'Согласование переноса срока',
    viewTogglerReject: 'Причина отклонения переноса срока',
};
