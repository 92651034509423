import { hasPermission } from '@/utils/Permissions';
import { isString } from 'lodash';
import Vue from 'vue';

Vue.directive('permission', {
    bind(el, { value, modifiers }) {
        if (isString(value) && !hasPermission(value)) {
            if (!modifiers.visible) el.style.display = 'none';
            el.classList.add('no-permission');
        }
    },
});
