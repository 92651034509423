export default {
    formTitle: 'Форма нарушения',
    formTitleAcceptance: 'Форма замечания тех. надзора',
    formTitleAcceptanceReport: 'Отчет от подрядчика',
    formTitleAcceptancePhoto: 'Фотографии',
    formTitleAcceptanceAct: 'Акт выполненных работ (по желанию)',
    formTitleAcceptanceActRequired: 'Акт выполненных работ',
    formTitleWarrantyActRequired: 'Акт выполненных работ',
    formTitleAcceptanceRemark: 'Замечания',
    formTitleWarrantyRemark: 'Замечания',
    formTitleAcceptanceActExpert: 'Акт эксперта',
    warranty: 'Гарантийные замечания',
};
