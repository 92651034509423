export default {
    title: 'У Вас нет уведомлений | У Вас {n} уведомление | У Вас {n} уведомления | У Вас {n} уведомлений',
    empty: 'Новых уведомлений нет',
    filter: {
        title: 'Уведомления',
        all: 'Все',
        unread: 'Непрочитанные',
        read: 'Прочитанные',
    },
    type: {
        System: 'Системное',
        Design: 'Проектирование',
        Violation: 'Предписания',
        Estimate: 'Сметы',
    },
    message: {
        sender: 'Т.ПРО',
        prescriptionNum: 'предписание № {n}',
        prescriptionNumChange: 'предписания № {n}',
        action: {
            create: 'создал',
            changeData: 'изменил данные',
            changeStatus: 'изменил статус',
        },
    },

    eventTypes: {
        drawingStatusesChange: 'Смена статусов чертежей',
        drawingDataRolesAssignment: 'Присвоение ролей в данных по чертежу',
        drawingAnnotationsCreation: 'Создание замечаний',
        drawingAnnotationsChange: 'Изменение замечаний',
        drawingAnnotationsByGroupMove: 'Перемещение замечаний по группам',
        drawingAnnotationsFulfillment: 'Выполнение замечаний',
        drawingAnnotationsNewMessage: 'Новые сообщения в замечаниях',
        drawingExpirationDate: 'Истечение срока',
        tasksAssignment: 'Назначение задач',
        tasksDataChange: 'Редактирование данных и описания задач',
        tasksStatusChange: 'Изменение статусов задач',
        tasksNewMessages: 'Новые сообщения в задачах',
        tasksFulfillment: 'Выполнение задач исполнителями',
        tasksDeadlineChange: 'Продление сроков задач',
        tasksCreate: 'Создание задачи',
        drawingAcceptReturnRequest: 'Принятие запроса на возврат чертежа в работу',
        drawingRejectReturnRequest: 'Отклонение запроса на возврат чертежа в работу',
        drawingCreateReturnRequest: 'Создание запроса на возврат чертежа в работу',
    },

    eventSectionTitle: {
        drawingNotifications: 'Уведомления по чертежам',
        tasksNotifications: 'Уведомления по задачам',
    },
};
