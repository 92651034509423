import oidc from '@/vue-oidc-client';

const HomeServicesPage = () => import('../pages/HomeServicesPage.vue');

export default [
    {
        path: '/',
        redirect: {
            name: 'services',
        },
    },

    {
        name: 'services',
        path: '/services',
        component: HomeServicesPage,
        meta: {
            authName: oidc.authName,
        },
    },
];
