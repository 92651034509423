export enum UserRole {
    Admin = 'admin',
    Customer = 'customer',
    GeneralContractor = 'generalContractor',
    SubContractor = 'subContractor',
    Designer = 'generalDesigner', // Куратор со стороны проектной организации
    Inspector = 'customerdesigner', // Куратор со стороны заказчика
    SuperAdmin = 'designSuperAdmin', // Супер админ (Проектирование)
}

export default UserRole;
