/* general localization  */
export default {
    naming: 'Название',
    actions: 'Действия',

    goBack: 'Вернуться назад',
    revoke: 'Отозвать',
    untie: 'Отвязать',
    cancel: 'Отменить',
    appoint: 'Назначить',
    create: 'Создать',
    update: 'Обновить',
    save: 'Сохранить',
    add: 'Добавить',
    delete: 'Удалить',
    edit: 'Редактировать',
    well: 'Хорошо',
    great: 'Отлично',
    complete: 'Завершить',
    confirm: 'Подтвердить',

    users: 'Пользователи',
    customer: 'Заказчик',
    customerPrescription: 'Заказчик предписаний',
    customerDesign: 'Заказчик проектирования',
    genContractor: 'Ген. подрядчик',
    genContractors: 'Ген. подрядчики',
    genDesigners: 'Ген. проектировщики',
    projectOrganization: 'Проектная организация',
    subContractor: 'Cуб. подрядчик',
    subContractors: 'Cуб. подрядчики',
    creationDate: 'Дата создания',
    address: 'Адрес',
    region: 'Регион',
    city: 'Город',
    cities: 'Город',
    citiesDashboard: 'Филиал',
    organisation: 'Организация',
    organisations: 'Организации',
    project: 'Проект',
    projects: 'Проекты',
    section: 'Секция',
    porch: 'Подъезд',
    sections: 'Секции',
    floor: 'Этаж',
    floors: 'Этажи',
    apartment: 'Квартира',
    apartments: 'Квартиры',

    name: 'Имя',
    surname: 'Фамилия',
    patronymic: 'Отчество',
    password: 'Пароль',
    passwordConfirm: 'Подтверждение пароля',
    role: 'Роль',
    roles: 'Роли',
    email: 'Электронная почта',
    position: 'Должность',
    inn: 'ИНН',
    kpp: 'КПП',
    ogrn: 'ОГРН',
    organisationType: 'Вид организации',
    fullName: 'Полное название',
    shortName: 'Краткое название',
    legalAddress: 'Юридической адрес',
    physicalAddress: 'Фактический адрес',
    contactNumber: 'Контактный номер {n}',
    projectPhoto: 'Изображение проекта',
    apartmentName: 'Название квартиры',
    director: 'Директор',

    dataSavedSuccessfully: 'Данные успешно сохранены',
    actualAddressIsDifferentFromLegal: 'Фактический адрес отличается от юридического',
    tryLaterIfError: 'Попробуйте позже. Если ошибка повторится, напишите в службу поддержки',

    current: 'Текущий',
    new: 'Новый',
    ver: 'Вер.',
    version: 'Версия',
    chat: 'Чат',
    copy: 'Копировать',
    send: 'Отправить',
    accept: 'Принять',
    completed: 'Завершено',
    perform: 'Выполнить',
    rejected: 'Отклонено',
    inWork: 'В работе',
    done: 'Выполнено',
    accepted: 'Принято',
    notAccepted: 'Не принято',
    created: 'Создано',
    agreed: 'Согласован',
    onChecking: 'На проверке',
    onRevision: 'На доработке',
    canceled: 'Отменен',
    draft: 'Черновик',
    clear: 'Понятно',
    error: 'Ошибка',
    remove: 'Удалить',
    attach: 'Прикрепить',
    filter: 'Фильтр',
    sort: 'Сортировка',
    saveSettings: 'Сохранить настройки',
    status: 'Статус',
    clearAll: 'Очистить все',
    documentType: 'Тип документа',
    download: 'Скачать',
    replacement: 'Замещения',
    beginDate: 'Дата начала',
    endDate: 'Дата окончания',
    comment: 'Комментарий',
    replaced: 'Замещаемый',
    substitute: 'Замещающий',
    serverError: 'Ошибка сервера',
    costFrom: 'Стоимость от',
    costTo: 'Стоимость до',
    select: 'Выбрать',
    or: 'или',
    print: 'Распечатать',
    object: 'Объект',
    cost: 'Цена',
    volume: 'Объём',
    number: 'Номер',
    executor: 'Исполнитель',
    type: 'Тип',
    realty: 'Недвижимость',
    realtyType: 'Тип недвижимости',
    to: 'До',
    remark: 'Замечание | Замечания',
    dateFrom: 'Дата от',
    dateTo: 'Дата до',
    show: 'Показать',
    more: 'больше',
    less: 'меньше',
    and: 'и',
    also: 'ещё',
    setting: 'Настройка<br/>уведомлений',
    notifications: 'Уведомления',
    from: 'от',
    numeroSign: '№',
    reject: 'Отклонить',
    reset: 'Сбросить',
    change: 'Изменить',
    applyClose: 'Применить и закрыть',
    files: 'Файлы',
    stopNow: 'Завершить',
    history: 'Журнал изменений',
    request: 'Запросить',
    good: 'Хорошо',
    subdevision: 'Подразделение',
    author: 'Автор',
    startedAt: 'Дата старта',
    createdAt: 'Дата создания',
    updatedAt: 'Дата обновления',
    title: 'Наименование',
    chart: 'График',
    documents: 'Документы',
    report: 'Отчеты',
    notAdded: 'Не добавлено',
    goOut: 'Выйти',
    viewing: 'Просмотр',
    compare: 'Сравнить',
    upload: 'Загрузить',
    continue: 'Продолжить',
    id: 'ID',
    note: 'Примечание',
    description: 'Описание',
    checker: 'Проверил',
    date: 'Дата',
    manager: 'Руководитель',
    designer: 'Куратор',
};
