import store from '@/store';
import router from '@/router';
import PermissionsEnum from '@/ship/Enums/PermissionsEnum';
import User from '@/ship/Models/User';

export const hasPermission = (permission: PermissionsEnum | string, user: User | null = store.state.user): boolean => {
    return user?.permissions?.includes(permission) ?? false;
};

export const hasAllPermissions = (
    permissions: PermissionsEnum[] | string[],
    user: User | null = store.state.user,
): boolean => {
    return permissions.every((permission) => user?.permissions?.includes(permission));
};

/**
 * Whether the current route has enough permissions
 */
export const hasCurrentRoutePermission = () => {
    const permissions = router.currentRoute.meta?.permissions;
    return permissions ? hasAllPermissions(permissions) : true;
};
