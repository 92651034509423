export default {
    // OrganisationTypeEnum
    physicalPerson: 'Физическое лицо',
    legalPerson: 'Юридическое лицо',
    separateSubdivision: 'Обособленное подразделение',

    // OrganisationRoleEnum
    customer: 'Заказчик предписаний',
    designCustomer: 'Заказчик проектирования',
    generalContractor: 'Генеральный подрядчик',
    generalDesigner: 'Проектная организация',
    subcontractor: 'Субподрядчик',
};
