export default {
    create: 'Создать предписание',
    toggler: 'Фильтры',
    status: 'Статус',
    city: 'Город',
    project: 'Проект',
    typeOfWork: 'Вид работ',
    section: 'Секция',
    floor: 'Этаж',
    find: 'Поиск',
    clear: 'Сбросить фильтры',
    executor: 'Исполнитель',
    deadline: 'Срок устранения',
    drawingType: 'Тип схемы',
    schemeType: 'Тип схемы',
    general: 'Генеральный подрядчик',
    subcontractor: 'Субподрядчик',
    author: 'Инициатор',
    apartment: 'Квартира',
    report: 'Отчет',
    flat: 'Квартира',

    placeholder: {
        chooseStatus: 'Выберите статус',
        chooseCity: 'Выберите город',
        chooseProject: 'Выберите проект',
        chooseTypeOfWork: 'Выберите тип работ',
        chooseSection: 'Выберите секцию',
        chooseFloor: 'Выберите этаж',
        chooseExecutor: 'Выберите исполнителя',
        chooseDeadlineRange: 'Выберите крайний срок',
        chooseDrawingType: 'Выберите тип схемы',
        chooseGeneral: 'Выберите генерального подрядчика',
        chooseSubcontractor: 'Выберите субподрядчика',
        chooseAuthor: 'Выберите инициатора',
        chooseRemarkType: 'Выберите тип замечания',
        chooseCustomer: 'Выберите заказчика',
        chooseRealtyType: 'Выберите тип недвижимости',
        choosePostalNumber: 'Выберите почтовый номер',
        chooseBuildingNumber: 'Выберите строительный номер',
        chooseName: 'Выберите ФИО',
        chooseFlat: 'Выберите квартиру',
    },
};
