export default {
    page: 'Страница',
    deleteQuestion: 'Вы уверены, что хотите удалить форму?',
    deleteTitle: 'Удаление',
    deleteAction: 'Удалить',
    uploadQuestion: 'Сохранить чертежи в системе?',
    uploadTitle: 'Сохранение',
    uploadAction: 'Сохранить',
    cancel: 'Отменить',
    uploadScheme: 'Загрузить чертеж',
    naming: 'Название',
    addPage: 'Добавить страницу',
    addPageWithCopy: 'Копировать страницу',
};
