import Vue from 'vue';
import { camelCaseFileName } from '@/utils/Helper';
import { parseDate } from '@/utils/ParseDate';
import dateFormat from 'dateformat';
import { DateTime } from 'luxon';

const configFiles = require.context('./', false, /^(?!.*(index)).*\.ts$/);

configFiles.keys().forEach((fileName: string) => {
    const name = camelCaseFileName(fileName.split('/').pop()!.split('.')[0]);

    Vue.filter(name, configFiles(fileName).default);
});

const simpleTime = (date: string) => {
    const messageDate = new Date(date);
    const today = new Date();
    const isToday = today.toDateString() === messageDate.toDateString();
    return dateFormat(messageDate, isToday ? 'H:MM' : 'H:MM dd.mm.yyyy');
};

const parseDateISO = (stringISO?: string) => stringISO && DateTime.fromISO(stringISO).toFormat('D');
const parseDateTimeISO = (stringISO?: string) => stringISO && DateTime.fromISO(stringISO).toFormat('f');

Vue.filter('parseDate', parseDate);
Vue.filter('simpleTime', simpleTime);
Vue.filter('parseDateISO', parseDateISO);
Vue.filter('parseDateTimeISO', parseDateTimeISO);
