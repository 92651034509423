export default {
    title: 'Заявка',
    name: {
        title: 'ФИО',
        placeholder: '-',
    },
    address: {
        title: 'Адрес',
        placeholder: '-',
    },
    flat: {
        title: 'Квартира',
        placeholder: '-',
    },
    description: {
        title: 'Описание проблемы',
        placeholder: '-',
    },
};
