import { VueConstructor } from 'vue/types/umd';
import IDeviceDetectorBreakpoints from './IDeviceDetectorBreakpoints';
import IDeviceDetectorOptions from './IDeviceDetectorOptions';

let breakpoints: IDeviceDetectorBreakpoints = {
    mobile: 0,
    tablet: 996,
    desktop: 1200,
};

// The plugin itself.
const DeviceDetector = {
    install(Vue: VueConstructor, options: IDeviceDetectorOptions) {
        // Check if an breakpoints has been defined in the splideOptions.
        if (options && options.breakpoints) {
            breakpoints = { ...breakpoints, ...options.breakpoints };
        }

        /*
         * Functions
         */

        /**
         * Check if the current device seems to be a mobile device.
         * Use the inner window width as reference.
         *
         * @return  {Boolean} true  - if it is a mobile device
         *                    false - if not
         */
        function isMobile() {
            return window.innerWidth >= breakpoints.mobile! && window.innerWidth < breakpoints.tablet!;
        }

        /**
         * Check if the current device seems to be a tablet device.
         * Use the inner window width as reference.
         *
         * @return  {Boolean} true  - if it is a tablet device
         *                    false - if not
         */
        function isTablet() {
            return window.innerWidth >= breakpoints.tablet! && window.innerWidth < breakpoints.desktop!;
        }

        /**
         * Check if the current device seems to be a desktop device.
         * Use the inner window width as reference.
         *
         * @return  {Boolean} true  - if it is a desktop device
         *                    false - if not
         */
        function isDesktop() {
            return window.innerWidth >= breakpoints.desktop!;
        }

        // Creates a new vue instance for getting the reactive properties.
        const vm = new Vue({
            data: {
                isMobile: isMobile(),
                isTablet: isTablet(),
                isDesktop: isDesktop(),
            },
        });

        // Adds the resize listener.
        window.addEventListener(
            'resize',
            () => {
                vm.isMobile = isMobile();
                vm.isTablet = isTablet();
                vm.isDesktop = isDesktop();
            },
            true,
        );

        // Defines the global getter $isMobile.
        Object.defineProperty(Vue.prototype, '$isMobile', {
            get() {
                return vm.isMobile;
            },
        });

        // Defines the global getter $isTablet.
        Object.defineProperty(Vue.prototype, '$isTablet', {
            get() {
                return vm.isTablet;
            },
        });

        // Defines the global getter $isDesktop.
        Object.defineProperty(Vue.prototype, '$isDesktop', {
            get() {
                return vm.isDesktop;
            },
        });
    },
};

// Define exports.
export default DeviceDetector;
