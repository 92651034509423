import Vuex, { StoreOptions } from 'vuex';

import { RootState } from './Root/Root';
import Vue from 'vue';
import root from './Root/Root';
import { camelCase } from 'lodash';

const stores: any = {};
const configFiles = require.context('./', true, /[^/]+Root\.ts$/);

configFiles.keys().forEach((fileName: string) => {
    const moduleName = camelCase(fileName.split('/')[1]);
    stores[moduleName] = {
        namespaced: true,
        ...configFiles(fileName).default,
    };
});

const store: StoreOptions<RootState> = {
    ...root,
    modules: stores,
    strict: process.env.NODE_ENV !== 'production',
};

Vue.use(Vuex);

export default new Vuex.Store(store);
