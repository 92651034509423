interface KeyValuePair<_Ty> {
    key: string;
    value: _Ty;
}

/**
 * The class helps to avoid duplicate promises.
 */
export default class PromiseKeeper {
    private _promises: Array<KeyValuePair<Promise<any>>> = [];

    /**
     * If a promise with the same key already exist we return it, otherwise creates one.
     *
     * @param key Unique key.
     * @param action Action that return the promise.
     */
    public get<_Ty>(key: string, action: () => Promise<_Ty>): Promise<_Ty> {
        const promise = this.getPromise<_Ty>(key);

        return promise ?? this.createPromise<_Ty>(key, action);
    }

    /**
     * Gets a promise by key.
     *
     * @param key Key of a promise.
     */
    private getPromise<_Ty>(key: string): Promise<_Ty> | undefined {
        const index = this.getPromiseIndex(key);

        if (index !== -1) {
            return this._promises[index].value;
        }
    }

    /**
     * Adds a new promise.
     *
     * @param key Key of a promise.
     */
    private addPromise<_Ty>(key: string, value: Promise<_Ty>): void {
        if (!this.hasPromise(key)) {
            this._promises.push({ key, value });
        }
    }

    /**
     * Removes a promise after execution.
     *
     * @param key Key of the promise.
     */
    private removePromise(key: string): void {
        const index = this.getPromiseIndex(key);

        if (index !== -1) {
            this._promises.splice(index, 1);
        }
    }

    /**
     * Determines whether a promise exists.
     *
     * @param key Key of a promise.
     */
    private hasPromise(key: string): boolean {
        return this.getPromiseIndex(key) !== -1;
    }

    /**
     * Gets the index of a promise.
     *
     * @param key Key of a promise.
     */
    private getPromiseIndex(key: string): number {
        return this._promises.findIndex((item) => item.key === key);
    }

    /**
     * Creates a new promise and add it to the list of promises.
     *
     * @param key Key of a promise.
     * @param action  Action that return the promise.
     */
    private createPromise<_Ty>(key: string, action: () => Promise<_Ty>): Promise<_Ty> {
        // Gets a promise from an action.
        const promise = action();

        promise.finally(() => {
            // When a promise is resolved we removes the promise from the list of promises.
            this.removePromise(key);
        });

        // Adds the promise to the list of promises.
        this.addPromise(key, promise);

        return promise;
    }
}

export const keeper = new PromiseKeeper();
