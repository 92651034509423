const isDevelopment = process.env.NODE_ENV === 'development';

export default [
    {
        name: 'cloud',
        path: '/cloud',
        beforeEnter() {
            location.href = isDevelopment ? 'https://cloud-dev.talan.group' : 'https://bim.talan.group';
        },
    },
    {
        name: 'qlik',
        path: '/qlik',
        beforeEnter() {
            location.href = 'https://qlik.talan.group';
        },
    },
];
