export default {
    'This action is unauthorized.': 'У вас недостаточно прав для выполнения данного действия',
    'The given data was invalid.': 'Введенные данные некорректны',
    'Организация с данным ИНН не найдена': 'Организация с данным ИНН не найдена',
    'Отправлять в работу может только представитель заказчика':
        'Отправлять в работу может только куратор со стороны заказчика',
    'Принять чертёж может только представитель заказчика': 'Принять чертёж может только куратор со стороны заказчика',
    'Отправлять чертёж на проверку могут только представитель или исполнители проектной организации':
        'Отправлять чертёж на проверку могут только куратор или исполнители проектной организации',
    'Создавать задачи может только представитель заказчика':
        'Создавать задачи может только куратор со стороны заказчика',
    'validation.unique': 'Значение поля {field} не является уникальным',
    'Design not found': 'Проектирование не найдено',
};
