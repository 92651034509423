const Error500Page = () => import('../pages/Errors/Error500Page.vue');
const Error404Page = () => import('../pages/Errors/Error404Page.vue');
import Router from 'vue-router';
import VueRouter, { RouteConfig } from 'vue-router';
import Vue from 'vue';
import store from '../store';
import oidc from '@/vue-oidc-client';
import i18n from '@/plugins/VueI18n';
import User from '@/ship/Models/User';

import PageService from '@/utils/PageService';

const routes: RouteConfig[] = [];
const context = require.context('.', false, /^(?!.*(index)).*\.ts$/);

context.keys().forEach((fileName: string) => {
    routes.push(...context(fileName).default);
});

routes.push({
    name: 'error.500',
    path: '/500',
    component: Error500Page,
});

routes.push({
    name: 'error.404',
    path: '/',
    component: Error404Page,
});

Vue.use(VueRouter);

const router = new Router({
    routes,
    mode: 'history',
});

oidc.useRouter(router);

router.beforeEach(async (to, _from, next) => {
    window.document.title = PageService.getWindowTitle(to);

    const isLoadingApp = store.state.isLoadingApp;

    const isSignInPage = to.name === `signinwin-${oidc.authName}`;

    if (isSignInPage) {
        return next();
    }

    const isAuthenticated = oidc.isAuthenticated;

    if (isLoadingApp && isAuthenticated) {
        try {
            const user: User = await store.dispatch('GET_USER_PROFILE');

            if (!user.roles || !user.roles.data.length) {
                store.commit('SET_APPLICATION_ERROR', i18n.t('errors.noAccessApp'));
            }

            next();
        } catch (error) {
            store.commit('SET_APPLICATION_ERROR', i18n.t('errors.failedToLoadApplication'));
        } finally {
            store.commit('SET_APP_LOADING', false);
        }

        return;
    } else {
        next();
    }
});

export default router;
