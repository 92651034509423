import Vue from 'vue';
import { createOidcAuth, SignInType } from 'vue-oidc-client/vue2';

const url = process.env.VUE_APP_URL!;

const authName = process.env.VUE_APP_OIDC_AUTH_NAME!;
const authority = process.env.VUE_APP_OIDC_AUTHORITY;
const clientId = process.env.VUE_APP_OIDC_CLIENT_ID;
const responseType = process.env.VUE_APP_OIDC_RESPONSE_TYPE;
const scope = process.env.VUE_APP_OIDC_SCOPE;

// SignInType could be Window or Popup
const oidc = createOidcAuth(authName, SignInType.Window, url, {
    authority,
    client_id: clientId,
    response_type: responseType,
    scope,
    automaticSilentRenew: false,
    monitorSession: false,
});

oidc.events.addUserSignedOut(() => {
    oidc.signIn();
});

Vue.prototype.$oidc = oidc;

export default oidc;
