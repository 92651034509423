export default {
    tCloudName: 'T.cloud',
    addFromComputer: 'Добавить с компьютера',
    loadIntoTable: 'Загрузить в таблицу',
    removeSelection: 'Снять выделение',
    chooseAll: 'Выбрать все',
    homeDirectoryTitle: 'Главная',
    uploadSimilarVersion: 'Файл "{name}" похож на новую версию "{similarName}", загрузить в качестве новой версии?',
    uploadNewVersion: 'Файл с именем "{name}" уже был загружен ранее, загрузить в качестве новой версии этого файла?',
    tCloudNotification: 'Уведомление от T.Cloud',
};
