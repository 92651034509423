import { GetterTree } from 'vuex';
import { DesignState } from '@/store/Design/DesignRoot';
import { mainBus } from '@/main';

export const getters: GetterTree<DesignState, DesignState> = {
    currentTab(state) {
        const routeId = mainBus.$route?.params.id;
        const routeName = mainBus.$route?.name ?? '';
        const isModel = routeName.includes('model');
        const tabs = isModel ? state.modelTabs : state.planTabs;
        return tabs.find(({ id }) => id.toString() === routeId);
    },

    currentDrawing(state, designGetters) {
        return designGetters.currentTab?.currentDrawing;
    },

    secondDrawing(state, designGetters) {
        return designGetters.currentTab?.secondDrawing;
    },

    planForm(state, designGetters) {
        return designGetters.currentTab?.planForm;
    },

    mainEditor(state, designGetters) {
        return designGetters.currentTab?.mainEditor;
    },

    secondEditor(state, designGetters) {
        return designGetters.currentTab?.secondEditor;
    },
};
export default getters;
