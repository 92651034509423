export default {
    header: 'Список документов',
    tCloudName: 'T.cloud',
    addFromComputer: 'Добавить с компьютера',
    attachFiles: 'Прикрепить файлы',
    chooseAll: 'Выбрать все',
    clearCheck: 'Снять выделение',
    needToSelectFiles: 'Необходимо выбрать документ',
    emptyList: 'Список загруженных документов пуст',
};
