export enum EstimateTableActionEnum {
    Open,
    ChangeData,
    ShowHistory,
}

export enum EstimateUserTypeEnum {
    Undefined,
    Contractor,
    Estimator,
    Supervisor,
    ProjectManager,
}
