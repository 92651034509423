import oidc from '@/vue-oidc-client';
import PermissionsEnum from '@/ship/Enums/PermissionsEnum';

const DesigningPage = () => import('@/pages/DesigningPage.vue');
const DesigningPlans = () => import('@/pages/Designing/DesigningPlans.vue');
const DesigningDrawings = () => import('@/pages/Designing/DesigningDrawings.vue');
const DesigningTasks = () => import('@/pages/Designing/DesigningTasks.vue');
const DesigningProjects = () => import('@/pages/Designing/DesigningProjects.vue');

export default [
    {
        name: 'designing',
        path: '/designing',
        component: DesigningPage,
        redirect: {
            name: 'designing.home.drawings',
            permissions: [PermissionsEnum.DesignsSearch],
        },
        children: [
            {
                name: 'designing.home',
                path: '/designing/home',
                redirect: { name: 'designing.home.drawings' },
            },
            {
                name: 'designing.home.drawings',
                path: '/designing/home/drawings',
                component: DesigningDrawings,
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'designing.plans',
                path: '/designing/plans/:id/:remarkId?/:remarkAction?',
                component: DesigningPlans,
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'designing.home.projects',
                path: '/designing/home/projects',
                component: DesigningProjects,
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'designing.home.tasks',
                path: '/designing/home/tasks/:taskId?/:taskAction?',
                component: DesigningTasks,
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'designing.home.models',
                path: '/designing/home/models',
                component: DesigningDrawings,
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'designing.model',
                path: '/designing/model/:id/:remarkId?/:remarkAction?',
                component: DesigningPlans,
                meta: {
                    authName: oidc.authName,
                },
            },
        ],
    },
];
