export default {
    deadline: 'Срок устранения замечаний',
    prescriptionNum: 'Предписание № {n}',
    areYouCompleteRemarks: 'Вы действительно устранили замечания?',
    areYouCompleteRemarksDescription:
        'Клиент получит уведомление, что все его замечания устранены, и будет приглашен на повторную приемку.',
    internalTerm: 'Срок (не виден клиентам)',
    eliminationDates: 'Даты устранения',
    remarksFrom: 'Замечания от',
    responsible: 'Ответственные',
    descriptionOfImplementedWorks: 'Описание выполненных работ...',
    error: 'Чтобы создать предписание, добавьте хотя бы 1 замечание',
    resolveRemarks: 'Вы действительно устранили замечание?',
    cancelRemarks: 'Вы действительно хотите отменить замечание?',
    copyRemarks: 'Вы действительно хотите скопировать все непринятые замечания из предписания №',
    copyRemarksError: 'В данном предписании отсутствуют отклоненные замечания',
};
