// Interfaces
import { MutationTree } from 'vuex';
import { DesignState, ModelTab, PlanTab } from '@/store/Design/DesignRoot';
import { DesignDrawing, DesignRemark, DesignRemarkGroup } from '@/ship/Models/Design';
import VDesigningPlansForm from '@/components/Designing/VDesigningPlansForm.vue';
import Vue from 'vue';
import ITab from '@/ship/Models/ITab';

// Utils
import { without } from 'lodash';
import { mainBus } from '@/main';
import VDesigningCanvasPointer from '@/components/Designing/VDesigningCanvasPointer.vue';
import { getComponentFunctions } from '@/utils/Helper';

export const mutations: MutationTree<DesignState> = {
    SET_DESIGN_PAGE_LOADING(state, loading: boolean) {
        state.designPageLoading = loading;
    },

    // Plans page
    SET_PLAN_FORM(state, params: { form: VDesigningPlansForm; tab?: PlanTab }) {
        if (!params.tab) return;
        params.tab.planForm = getComponentFunctions(params.form);
    },

    SET_PLAN_MAIN_EDITOR(state, params: { editor: VDesigningCanvasPointer; tab?: PlanTab }) {
        if (!params.tab) return;
        params.tab.mainEditor = getComponentFunctions(params.editor);
    },

    SET_PLAN_SECOND_EDITOR(state, params: { editor: VDesigningCanvasPointer; tab?: PlanTab }) {
        if (!params.tab) return;
        params.tab.secondEditor = getComponentFunctions(params.editor);
    },

    SET_CURRENT_DRAWING(state, params: { drawing: DesignDrawing | null; tab?: PlanTab }) {
        if (!params.tab) return;
        params.tab.currentDrawing = params.drawing ? { ...params.drawing, clonedDesignRemark: null } : params.drawing;
    },

    SET_SECOND_DRAWING(state, params: { drawing: DesignDrawing | null; tab?: PlanTab }) {
        if (!params.tab) return;
        params.tab.secondDrawing = params.drawing ? { ...params.drawing, clonedDesignRemark: null } : params.drawing;
    },

    SET_SELECTED_REMARK(state, params: { selectedRemarkId?: number; drawings: DesignDrawing[] }) {
        const toggleDrawingRemarkSelection = (drawing: DesignDrawing | null) => {
            if (drawing?.groups?.data) {
                drawing.groups.data = drawing.groups?.data.map((group) => {
                    group.tasks?.data.forEach((remark) => {
                        remark.selected = remark.id === params.selectedRemarkId;
                        if (remark.selected) group.open = true;
                    });
                    return group;
                });
            }
        };

        params.drawings.forEach((drawing) => toggleDrawingRemarkSelection(drawing));
    },

    SET_REMARK_VALUE(
        state,
        params: { remark: DesignRemark; key: keyof DesignRemark; value: DesignRemark[keyof DesignRemark] },
    ) {
        Vue.set(params.remark, params.key, params.value);
    },

    SET_GROUP_VALUE(
        state,
        params: {
            group: DesignRemarkGroup;
            key: keyof DesignRemarkGroup;
            value: DesignRemarkGroup[keyof DesignRemarkGroup];
        },
    ) {
        Vue.set(params.group, params.key, params.value);
    },

    REMOVE_GROUP(state, params: { drawing: DesignDrawing; removableGroup: DesignRemarkGroup }) {
        params.drawing.groups.data = without(params.drawing.groups.data, params.removableGroup);
    },

    ADD_GROUP_IN_DRAWING(state, params: { drawing: DesignDrawing; group: DesignRemarkGroup }) {
        const groups = params.drawing.groups.data;
        groups.push(params.group);
        Vue.set(groups[groups.length - 1], 'open', groups[groups.length - 1].open);
    },

    REPLACE_GROUP_IN_DRAWING(state, params: { drawing: DesignDrawing; group: DesignRemarkGroup }) {
        params.drawing.groups.data = params.drawing.groups.data.map((group) => {
            if (group.id === params.group.id) {
                Vue.set(params.group, 'open', params.group.open);
                return params.group;
            }
            return group;
        });
    },

    // Plan tabs

    CREATE_HEADER_TAB(state, tab: ITab) {
        if (!state.planTabs.some(({ id }) => tab.id === id)) {
            state.planTabs.push({
                ...tab,
                currentDrawing: null,
                secondDrawing: null,
                planForm: null,
                mainEditor: null,
                secondEditor: null,
            });
        } else {
            state.planTabs.forEach((planTab) => {
                if (planTab.id === tab.id) {
                    if (tab.taskId) planTab.taskId = tab.taskId;
                    if (tab.name !== planTab.name) planTab.name = tab.name;
                }
            });
        }
    },

    SELECT_HEADER_TAB(state, id: number) {
        state.planTabs.forEach((tab) => (tab.isOpen = tab.id === id));

        if (id >= 0 && mainBus.$route.params?.id !== id.toString()) {
            mainBus.$router.push({ name: 'designing.plans', params: { id: id.toString() } });
        }
    },

    SAVE_TABS(state) {
        if (state.tabsLoading) return;
        localStorage.setItem('designingTabs', JSON.stringify(state.planTabs));
    },

    SET_TABS(state, tabs: PlanTab[]) {
        state.planTabs = tabs;
        state.tabsLoading = false;
    },

    CLOSE_HEADER_TAB(state, id: number) {
        state.planTabs = state.planTabs.filter((tab) => tab.id !== id);
    },

    CLEAR_TABS(state) {
        state.planTabs.forEach((tab) => {
            tab.currentDrawing = null;
            tab.secondDrawing = null;
            tab.mainEditor = null;
            tab.secondEditor = null;
            tab.planForm = null;
        });
    },

    SET_CLONED_REMARK(state, params: { currentDrawing: DesignDrawing; remark: DesignRemark | null }) {
        if (params.currentDrawing) params.currentDrawing.clonedDesignRemark = params.remark;
    },

    // Model tabs

    CREATE_MODEL_HEADER_TAB(state, tab: ITab) {
        if (!state.modelTabs.some(({ id }) => tab.id === id)) {
            state.modelTabs.push({
                ...tab,
                currentDrawing: null,
                planForm: null,
                mainEditor: null,
            });
        } else {
            state.modelTabs.forEach((modelTab) => {
                if (modelTab.id === tab.id) {
                    if (tab.taskId) modelTab.taskId = tab.taskId;
                    if (tab.name !== modelTab.name) modelTab.name = tab.name;
                }
            });
        }
    },

    SELECT_MODEL_HEADER_TAB(state, id: number) {
        state.modelTabs.forEach((tab) => (tab.isOpen = tab.id === id));

        if (id >= 0 && mainBus.$route.params?.id !== id.toString()) {
            mainBus.$router.push({ name: 'designing.model', params: { id: id.toString() } });
        }
    },

    SAVE_MODEL_TABS(state) {
        if (state.tabsLoading) return;
        localStorage.setItem('modelTabs', JSON.stringify(state.modelTabs));
    },

    SET_MODEL_TABS(state, tabs: ModelTab[]) {
        state.modelTabs = tabs;
        state.tabsLoading = false;
    },

    CLOSE_MODEL_HEADER_TAB(state, id: number) {
        state.modelTabs = state.modelTabs.filter((tab) => tab.id !== id);
    },

    CLEAR_MODEL_TABS(state) {
        state.modelTabs.forEach((tab) => {
            tab.currentDrawing = null;
            tab.mainEditor = null;
            tab.planForm = null;
        });
    },
};
export default mutations;
