import { parseDate, prepareDate } from '@/utils/ParseDate';
import { get, set } from 'lodash';
import IDictionary from '@/ship/Models/IDictionary';

const changeDateFormat = <T extends IDictionary>(model: T, path: string, changer: (date: string) => string) => {
    const value = get(model, path);
    if (value) set(model, path, changer(value));
};

export const formatToSendAny = <T extends IDictionary>(model: T, dateFieldsPaths: string[] = []) => {
    dateFieldsPaths.forEach((path) => changeDateFormat(model, path, prepareDate));
    return model;
};

export const formatToEditAny = <T extends IDictionary>(model: T, dateFieldsPaths: string[] = []) => {
    dateFieldsPaths.forEach((path) => changeDateFormat(model, path, parseDate));
    return model;
};
