export default {
    app: {
        name: 'PRO',
        search: 'Search',
    },

    user: {
        desk: {
            settings: 'Settings',
            logout: 'Logout',
            history: 'History',
        },
    },

    titles: {
        catalog: 'General journal',
        snips: 'Building codes and regulations',
        add: 'Add',
    },

    page: {
        violationForm: {
            customer: 'Customer',
            generalContractor: 'General contracotr',
            subContractor: 'Subcontractor',
            project: 'Project',
            section: 'Section',
            floor: 'Floor',
            typeOfWorks: 'Type of work',
            basicRequirement: 'Requirement',
            deadline: 'Date of elimination',
            photos: 'Photos and attachments',
            photosUpload: 'Upload a photo',
            photosCount: 'Number of uploaded photos',
            description: 'Description',
            sendButton: 'Send',
            upload: 'Upload',
            selectFile: 'Select a file',

            setGeneralContractor: 'Specify the general contractor',
            setSubContractor: 'Specify the subcontractor',
            setProject: 'Select a project',
            setSection: 'Select a section',
            setFloor: 'Select a floor',
            setTypeOfWorks: 'Select the type of work',
            setRequirement: 'Select a position',

            rejectCauses: 'The reason for the rejection',
            postponement: 'Postpone',
            create: 'Create',
            violation: 'Prescription',
            display: 'Show',
            edit: 'Edit',
            cancel: 'Cancel',
            violationCatalog: 'Violation log',
            formCreating: 'Form for creating a prescription',
        },

        filter: {
            create: 'Create a prescription',
            toggler: 'Filters',
            status: 'Status',
            city: 'City',
            project: 'Project',
            typeOfWork: 'Type of work',
            section: 'Section',
            floor: 'Floor',
            find: 'Search',
            clear: 'Remove filters',
            executor: 'Executor',
            deadline: 'Deadline',
            drawingType: 'Scheme type',
            general: 'General contractor',
            subcontractor: 'Subcontractor',
            author: 'Author',

            placeholder: {
                chooseStatus: 'Select a status',
                chooseCity: 'Select a city',
                chooseProject: 'Select a project',
                chooseTypeOfWork: 'Select the type of work',
                chooseSection: 'Select a section',
                chooseFloor: 'Select a floor',
                chooseExecutor: 'Select an executor',
                chooseDeadlineRange: 'Select a deadline',
                chooseDrawingType: 'Select the schema type',
                chooseGeneral: 'Select a general contractor',
                chooseSubcontractor: 'Select a subcontractor',
                chooseAuthor: 'Select an author',
            },
        },

        modal: {
            storage: 'A network error occurred during the last session, do you want to restore the data?',
            deletePoint: 'Are you sure you want to delete the violation entry № {index} ?',
            answer: {
                positive: 'Yes',
                negative: 'No',
            },
        },

        localStorage: {
            editTitle: 'Редактировать шаблон',
        },

        report: {
            name: 'Reports',
        },

        home: {
            name: 'Home',
            city: 'City',
            objects: 'Object',
            snip: 'Footing',
            statistics: 'Statistics',
        },

        login: {
            name: 'Authorization',
            login: 'Login',
            invalidData: 'invalid username or password',

            form: {
                username: 'Username',
                password: 'Password',
            },
            forgot: {
                password: 'Forgot your password ?',
            },
            camelCase: {
                mail: 'sample@email.com',
            },
        },

        forgotPassword: {
            name: 'To recover the password',
            mail: 'sample@email.com',
            continue: 'Continue',
        },

        resetPassword: {
            title: 'Password recovery',
            name: 'Enter your new password',
            retryname: 'Confirm your new password',
            btn: 'Change your password',
        },

        resetOldPassword: {
            title: 'Changing your password',
            oldpass: 'Enter your old password',
            name: 'Enter your new password',
            retryname: 'Confirm your new password',
            btn: 'Change your password',
        },

        typeForm: {
            type: 'Type of work',
            closeViolation: 'To close the breach',
            back: 'Go back to the form',
            causes: 'Viewing the reason for rejection',
        },

        close: {
            done: 'Perform',
        },

        violation: {
            reject: {
                cancel: 'Cancel',
                confirm: 'Confirm',
            },
        },

        displayClose: {
            accept: 'Accept',
            reject: 'Reject',
        },

        managerSelector: {
            project: 'Project',
            customer: 'Customer',
            general: 'The general contractor',
            subcontractor: 'Subcontractor',
            executor: 'Executor',
            dateResolve: 'Date of elimination',
            responsiblePersons: 'Responsible persons',
            projectHaveNotCustomer: 'This project does not have a customer',
            projectHaveNotGeneralContractor: 'This project does not have a general contractor',
        },

        multiselectorSlot: {
            noDataAvailable: 'No data available',
            elementByRequestNotFound: 'No elements were found for this query',
        },

        schemeSelector: {
            schemeParams: 'Schema parameters',
            schemeType: 'Type of scheme',
            scheme: 'Scheme',
            section: 'Section',
            floor: 'Floor',
            apartment: 'Flat',
        },

        dateSelector: {
            date: 'Date',
        },

        notification: {
            title: `You don't have any notifications | You have {n} notification | You have {n} notifications`,
        },

        designing: {
            table: {
                alert: 'Select a drawing',
            },
        },
    },

    homeMenu: {
        name: 'Project',
        back: 'Back',
        sample: 'Example of a button',
    },
    homeCities: {
        objects: 'Objects',
        sections: 'Sections',
        object: 'Object',
        section: 'Section',
        statistics: 'Statistics',
        specifications: 'Specifications',
    },

    table: {
        empty: 'There are no violations',
    },

    modal: {
        accept: 'Accept',
        errors: {
            error: 'Error',
            server: 'Failed to send data to the server',
        },
    },

    errors: {
        error: 'An error occurred while accessing the server',
    },

    messages: {
        requireField: 'This field is required',
    },

    director: 'Director',
};
