export default {
    schemeType: {
        title: 'Тип',
        placeholder: 'Выберите тип схемы',
        select: 'Нажмите, чтобы выбрать',
        deselect: 'Нажмите, чтобы убрать',
        selected: 'Выбрано',
    },
    schemeParams: 'Параметры',
    scheme: {
        title: 'Схема',
        placeholder: 'Выберите схему',
        select: 'Нажмите, чтобы выбрать',
        deselect: 'Нажмите, чтобы убрать',
        selected: 'Выбрано',
    },
    schemeSection: {
        title: 'Схема секции',
        placeholder: 'Выберите схему',
        select: 'Нажмите, чтобы выбрать',
        deselect: 'Нажмите, чтобы убрать',
        selected: 'Выбрано',
    },
    schemeFloor: {
        title: 'Схема этажа',
        placeholder: 'Выберите схему',
        select: 'Нажмите, чтобы выбрать',
        deselect: 'Нажмите, чтобы убрать',
        selected: 'Выбрано',
    },
    schemeApartment: {
        title: 'Схема квартиры',
        placeholder: 'Выберите схему',
        select: 'Нажмите, чтобы выбрать',
        deselect: 'Нажмите, чтобы убрать',
        selected: 'Выбрано',
    },
    section: {
        title: 'Секция',
        placeholder: 'Выберите секцию',
        select: 'Нажмите, чтобы выбрать',
        deselect: 'Нажмите, чтобы убрать',
        selected: 'Выбрано',
    },
    floor: {
        title: 'Этаж',
        placeholder: 'Выберите этаж',
        select: 'Нажмите, чтобы выбрать',
        deselect: 'Нажмите, чтобы убрать',
        selected: 'Выбрано',
    },
    apartment: {
        title: 'Квартира',
        placeholder: 'Выберите квартиру',
        select: 'Нажмите, чтобы выбрать',
        deselect: 'Нажмите, чтобы убрать',
        selected: 'Выбрано',
    },
    withoutDesign: 'Чертеж не требуется',
};
