export default {
    accept: 'Принять',
    errors: {
        error: 'Ошибка',
        server: 'Не удалось отправить данные на сервер',
    },
    title: {
        cantChangeChart: 'Невозможно изменить график',
        cantChangeSection: 'Невозможно перейти в раздел',
        cantFindDataByFilter: 'По указанным параметрам фильтров объекты не найдены!',
    },
    description: {
        cantChangeChart:
            'Пока документ "Базовый план-график" находится на согласовании в ЭДО, вы не можете изменять текущую версию',
        cantChangeSection: 'Чтобы перейти в данный раздел, создайте структуру графика',
    },
};
