export default {
    project: 'Проект',
    customer: 'Заказчик',
    general: 'Генподрядчик',
    subcontractor: 'Субподрядчик',
    executor: 'Исполнитель',
    dateResolve: 'Срок устранения',
    dateCreated: 'Дата обращения',
    responsiblePersons: 'Ответственные',
    projectHaveNotCustomer: 'У данного проекта отсутствует заказчик',
    projectHaveNotGeneralContractor: 'У данного проекта отсутствует генподрядчик',
};
