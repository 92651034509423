// Filters
import './filters';

// Plugins
import './plugins/Axios';
import './plugins/VueI18n';
import './plugins/VueBus';
import './plugins/VueSelect';
import './plugins/VueFlatPickr';
import './plugins/MobileHelper';
import './plugins/VueMultiselect';
import './plugins/VueModal';
import './plugins/VueDeviceDetector';
import './plugins/VueDragscroll';
import './plugins/VTooltip';
import './plugins/VMask';
import './plugins/VueRangeSlider';
import './plugins/VueApexCharts';

import './directives/VClickOutside';
import './directives/VFocus';
import './directives/VPermission';
import './directives/VObserveVisibility';

import App from './App.vue';
import Vue from 'vue';
import i18n from './plugins/VueI18n';
import router from './router';
import store from './store';
import oidc from './vue-oidc-client';

// Must be imported after oidc client for correct API validation rules
import './plugins/VeeValidate';

export let mainBus = new Vue();

Vue.config.productionTip = false;

oidc.startup().then((ok) => {
    if (ok) {
        mainBus = new Vue({
            i18n,
            store,
            router,
            render: (h) => h(App),
        }).$mount('#app');
    } else {
        mainBus = new Vue({
            render: (h) => h('h1', 'Something went wrong...'),
        }).$mount('#app');
    }
});
