export default {
    fieldNameLabel: 'Введите название',
    findByINN: 'Заполнить по ИНН',

    cityCreated: 'Город успешно создан',
    organisationCreated: 'Организация успешно создана',
    projectCreated: 'Проект успешно создан',

    noRoles: 'Нет ролей',
    noPosition: 'Нет должности',
    addressNotStated: 'Адрес не указан',
    cityNotStated: 'Город не указан',

    chooseProjectFromList: 'Выберите проект из списка',
    chooseUsersFromList: 'Выберите пользователей из списка',
    userDeleted: 'Пользователь удален',
    addToNewProject: 'Добавить в новый проект',
    userProjects: 'Проекты пользователя',
    usersProjectEmptyTableMessage: 'Пользователь не состоит ни в одном проекте',
    sureRevokePowers: 'Действительно хотите отозвать полномочия пользователя?',
    sureToDeleteUser: 'Вы действительно хотите удалить пользователя {0}',
    invitationSentToEmail: 'Приглашение в систему было отправлено пользователю на e-mail «{0}»',

    organisationCreate: 'Создать секцию',
    organisationCreating: 'Создание секции',
    organisationEditing: 'Редактирование секции',
    organisationName: 'Название секции',
    organisationNamePlaceholder: 'Например, «Секция №1»',
    organisationSubEmptyTableMessage: 'У вас нет добавленных суб. подрядчиков',
    organisationGenEmptyTableMessage: 'У вас нет добавленных ген. подрядчиков',
    organisationSubModalTitleMessage: 'Выберите суб. подрядчика из списка',
    organisationGenModalTitleMessage: 'Выберите ген. подрядчика из списка',
    organisationSubModalLabelMessage: 'Выберите суб. подрядчика',
    organisationGenModalLabelMessage: 'Выберите ген. подрядчика',

    sectionCreate: 'Создать секцию',
    sectionCreating: 'Создание секции',
    sectionEditing: 'Редактирование секции',
    sectionName: 'Название секции',
    sectionEmptyTableMessage: 'Вы можете создать секцию к этому проекту',
    sectionNamePlaceholder: 'Например, «Секция №1»',

    floorCreate: 'Создать этаж',
    floorCreating: 'Создание этажа',
    floorEditing: 'Редактирование этажа',
    floorName: 'Название этажа',
    floorEmptyTableMessage: 'Вы можете создать этаж в этой секции',
    floorNamePlaceholder: 'Например, «Этаж №1»',

    apartmentCreate: 'Создать квартиру',
    apartmentCreating: 'Создание квартиры',
    apartmentEditing: 'Редактирование квартиры',
    apartmentName: 'Название квартиры',
    apartmentEmptyTableMessage: 'Вы можете создать квартиру в этом этаже',
    apartmentNamePlaceholder: 'Например, «Квартира №1»',

    sureToDeleteReplacement: 'Вы действительно хотите удалить замещение {0} на {1}?',
    sureToStopReplacement: 'Действительно хотите завершить замещение прямо сейчас?',
    replacementWillStopNow: 'Замещение для {0} с {1} по {2} будет завершено сейчас.',
    replacementStopped: 'Замещение завершено.',
    replacementDeleted: 'Замещение удалено.',
};
