import Vue from 'vue';
import VueModal from 'vue-js-modal/dist/index.nocss.js';

Vue.use(VueModal, {
    dynamicDefaults: {
        resizable: false,
        adaptive: true,
        draggable: false,
        scrollable: true,
        focusTrap: true,
        reset: true,
        clickToClose: true,
        width: '94%',
        height: 'auto',
    },
});
