import VueI18n from '@/plugins/VueI18n';
import { Route, Location } from 'vue-router';
import { camelCase } from 'lodash';

export default class PageService {
    public static getPageTitle(route: Route | Location): string {
        if (!route.name) return '';

        const routeName = `routes.${camelCase(route.name)}`;
        return VueI18n.te(routeName) ? VueI18n.t(routeName).toString() : route.name;
    }

    public static getWindowTitle(
        route: Route | Location,
        separator = '-',
        complement = VueI18n.t('app.name').toString(),
    ): string {
        const title = this.getPageTitle(route);
        return title ? `${title} ${separator} ${complement}`.trim() : complement;
    }
}
