import VueI18n from '@/plugins/VueI18n';
import { hasPermission } from './Permissions';

// Enums
import UserRole from '@/ship/Enums/UserRole';
import PermissionsEnum from '@/ship/Enums/PermissionsEnum';

// Interfaces
import User from '@/ship/Models/User';

export default class UserService {
    public static getRoleNames(user?: Partial<User> | null): string {
        if (!user?.roles?.data.length) {
            return VueI18n.t('page.administration.noRoles').toString();
        }

        return user.roles.data.map(({ displayName }) => displayName).join(', ');
    }

    public static getShortName(user?: User | null): string {
        if (!user) return '';

        if (user.firstName) {
            if (user.surname) {
                return `${user.firstName}  ${user.surname}`;
            }

            return user.firstName;
        }

        return user.name.split(' ').slice(0, 2).reverse().join(' ');
    }

    public static getUserAbbreviation(user?: User | null): string {
        if (!user) return '';

        const userName = user.name;

        const namesArray = user.name.split(' ');

        if (namesArray.length > 1) {
            return namesArray
                .slice(0, 2)
                .map((word) => word.charAt(0))
                .join('');
        }

        return userName.slice(0, 2);
    }

    public static isAdmin(user?: User | null): boolean {
        return user?.roles?.data.some(({ name }) => name === UserRole.Admin) ?? false;
    }

    public static isSuperAdmin(user?: User | null): boolean {
        return user?.roles?.data.some(({ name }) => name === UserRole.SuperAdmin) ?? false;
    }

    public static isCustomer(user?: User | null): boolean {
        return user?.roles?.data.some(({ name }) => name === UserRole.Customer) ?? false;
    }

    public static isGeneralContractor(user?: User | null): boolean {
        return user?.roles?.data.some(({ name }) => name === UserRole.GeneralContractor) ?? false;
    }

    public static isSubContractor(user?: User | null): boolean {
        return user?.roles?.data.some(({ name }) => name === UserRole.SubContractor) ?? false;
    }

    public static isDesigner(user?: User | null): boolean {
        return user?.roles?.data.some(({ name }) => name === UserRole.Designer) ?? false;
    }

    public static isInspector(user?: User | null): boolean {
        return user?.roles?.data.some(({ name }) => name === UserRole.Inspector) ?? false;
    }

    /**
     * Whether the user is Designing subsystem user
     *
     * @param user any application user, if not set, works with the current user
     */
    public static isDesigningSubsystemUser(user?: User | null): boolean {
        return hasPermission(PermissionsEnum.DesignsSearch, user);
    }
}
