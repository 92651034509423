export default {
    typeOfWork: 'Вид работ',
    requirement: 'Основание',
    deadline: 'Крайний срок',
    description: 'Описание',
    content: 'Содержание',
    comment: 'Комментарий',
    title: 'Нарушения',
    select: {
        chooseRequirement: 'Выберите основание',
        selectLabel: 'Нажмите, чтобы выбрать',
        deselectLabel: 'Нажмите, чтобы убрать',
    },
    requirementCustom: {
        differentRequirement: 'Другое основание',
        differentRequirements: 'Другие основания',
    },
    close: {
        done: 'Выполнить',
        completeWork: 'Описание выполненных работ',
        description: 'Описание',
    },
    reject: {
        cancel: 'Отменить',
        confirm: 'Подтвердить',
        causes: 'Причина отклонения',
        requireField: 'Поле обязательно для заполнения',
    },
};
