export default {
    title: 'Ответственные',
    project: {
        title: 'Проект',
        placeholder: '-',
    },
    customer: {
        title: 'Заказчик',
        placeholder: '-',
    },
    general: {
        title: 'Генподрядчик',
        placeholder: '-',
    },
};
