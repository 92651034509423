import oidc from '@/vue-oidc-client';
import PermissionsEnum from '@/ship/Enums/PermissionsEnum';

const EstimatesMain = () => import('@/pages/Estimates/EstimatesMain.vue');
const EstimatesProjects = () => import('@/pages/Estimates/EstimatesProjects.vue');
const EstimatesJournal = () => import('@/pages/Estimates/EstimatesJournal.vue');
const EstimatesListActs = () => import('@/pages/Estimates/EstimatesListActs.vue');
const EstimatesPage = () => import('@/pages/Estimates/EstimatesPage.vue');
const EstimatesActPage = () => import('@/pages/Estimates/EstimatesActPage.vue');
const EstimatesAdditionalPage = () => import('@/pages/Estimates/EstimatesAdditionalPage.vue');

const permissions = [PermissionsEnum.EstimatesGet];

export default [
    {
        name: 'estimates',
        path: '/estimates',
        component: EstimatesMain,
        meta: {
            authName: oidc.authName,
            permissions,
        },
        children: [
            {
                name: 'estimates.projects',
                path: 'projects',
                component: EstimatesProjects,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'estimates.journal',
                path: 'journal',
                component: EstimatesJournal,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'estimates.list-acts',
                path: 'list-acts',
                component: EstimatesListActs,
                meta: { authName: oidc.authName, showSidebar: true, permissions },
            },
            {
                name: 'estimates.estimate',
                path: 'estimate/:id',
                component: EstimatesPage,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'estimates.additional',
                path: 'additional/:id',
                component: EstimatesAdditionalPage,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'estimates.act',
                path: 'act/:id',
                component: EstimatesActPage,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
            {
                name: 'estimates.contract',
                path: 'contract/:id',
                component: EstimatesPage,
                meta: { authName: oidc.authName, showSidebar: false, permissions },
            },
        ],
    },
];
