export default {
    designing: 'Проектирование',
    estimates: 'Сметы',
    prescription: 'Предписания',
    acceptance: 'Приёмка квартир',
    warranty: 'Гарантия',
    cloud: 'T.Cloud',
    qlik: 'Qlik',
    adminPanel: 'Администрирование',
    projectManagement: 'Управление проектами',
};
