import OrganisationRoleEnum from '@/ship/Enums/OrganisationRoleEnum';

// Interfaces
import Organisation from '@/ship/Models/Organisation';

export default class OrganisationService {
    public static isCustomer(organisation?: Organisation): boolean {
        return !!organisation?.roles.includes(OrganisationRoleEnum.Customer);
    }

    public static isDesignCustomer(organisation?: Organisation): boolean {
        return !!organisation?.roles.includes(OrganisationRoleEnum.DesignCustomer);
    }

    public static isGeneralContractor(organisation?: Organisation): boolean {
        return !!organisation?.roles.includes(OrganisationRoleEnum.GeneralContractor);
    }

    public static isSubcontractor(organisation?: Organisation): boolean {
        return !!organisation?.roles.includes(OrganisationRoleEnum.Subcontractor);
    }

    public static isGeneralDesigner(organisation?: Organisation): boolean {
        return !!organisation?.roles.includes(OrganisationRoleEnum.GeneralDesigner);
    }

    /**
     * Whether the organisation is related to Designing subsystem
     */
    public static isDesigningSubsystemOrganisation(organisation: Organisation): boolean {
        return this.isDesignCustomer(organisation) || this.isGeneralDesigner(organisation);
    }

    /**
     * Whether the organisation is related to Prescriptions subsystem
     */
    public static isPrescriptionsSubsystemOrganisation(organisation: Organisation): boolean {
        return (
            this.isCustomer(organisation) ||
            this.isGeneralContractor(organisation) ||
            this.isSubcontractor(organisation)
        );
    }
}
