import PermissionsEnum from '@/ship/Enums/PermissionsEnum';

import oidc from '@/vue-oidc-client';

const OrganisationsPage = () => import('@/pages/Administration/OrganisationsPage.vue');
const OrganisationPage = () => import('@/pages/Administration/OrganisationPage.vue');
const ProjectsPage = () => import('@/pages/Administration/ProjectsPage.vue');
const ProjectCreatePage = () => import('@/pages/Administration/ProjectCreatePage.vue');
const ProjectEditPage = () => import('@/pages/Administration/ProjectEditPage.vue');
const UsersPage = () => import('@/pages/Administration/UsersPage.vue');
const ReplacementPage = () => import('@/pages/Administration/ReplacementPage.vue');
const ReplacementEditPage = () => import('@/pages/Administration/ReplacementEditPage.vue');
const UserCreatePage = () => import('@/pages/Administration/UserCreatePage.vue');
const UserEditPage = () => import('@/pages/Administration/UserEditPage.vue');
const CitiesPage = () => import('@/pages/Administration/CitiesPage.vue');
const CityCreatePage = () => import('@/pages/Administration/CityCreatePage.vue');
const CityEditPage = () => import('@/pages/Administration/CityEditPage.vue');
const SectionEditPage = () => import('@/pages/Administration/SectionEditPage.vue');
const FloorEditPage = () => import('@/pages/Administration/FloorEditPage.vue');
const ApartmentEditPage = () => import('@/pages/Administration/ApartmentEditPage.vue');
const AdministrationPage = () => import('@/pages/AdministrationPage.vue');
const NotificationsPage = () => import('@/pages/Administration/NotificationsPage.vue');

export default [
    {
        name: 'administration',
        path: '/administration',
        component: AdministrationPage,
        meta: {
            authName: oidc.authName,
            permissions: [
                PermissionsEnum.OrganisationsUpdate,
                PermissionsEnum.ProjectsUpdate,
                PermissionsEnum.UsersUpdate,
                PermissionsEnum.CitiesUpdate,
            ],
        },
        children: [
            {
                name: 'administration.organisations',
                path: 'organisations',
                component: OrganisationsPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.OrganisationsUpdate],
                },
            },
            {
                name: 'administration.organisations.create',
                path: 'organisations/create',
                component: OrganisationPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.OrganisationsCreate],
                },
            },
            {
                name: 'administration.organisations.edit',
                path: 'organisations/edit/:id',
                component: OrganisationPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.OrganisationsUpdate],
                },
            },
            {
                name: 'administration.projects',
                path: 'projects',
                component: ProjectsPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.ProjectsUpdate],
                },
            },
            {
                name: 'administration.projects.create',
                path: 'projects/create',
                component: ProjectCreatePage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.ProjectsCreate],
                },
            },
            {
                name: 'administration.projects.edit',
                path: 'projects/edit/:id',
                component: ProjectEditPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.ProjectsUpdate],
                },
            },
            {
                name: 'administration.users',
                path: 'users',
                component: UsersPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.UsersUpdate],
                },
            },
            {
                name: 'administration.users.create',
                path: 'users/create',
                component: UserCreatePage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.UsersUpdate],
                },
            },
            {
                name: 'administration.users.edit',
                path: 'users/edit/:id',
                component: UserEditPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.UsersUpdate],
                },
            },
            {
                name: 'administration.replacement',
                path: 'replacement',
                component: ReplacementPage,
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'administration.replacement.edit',
                path: 'replacement/edit/:id',
                component: ReplacementEditPage,
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'administration.replacement.create',
                path: 'replacement/create',
                component: ReplacementEditPage,
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'administration.cities',
                path: 'cities',
                component: CitiesPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.CitiesUpdate],
                },
            },
            {
                name: 'administration.cities.create',
                path: 'cities/create',
                component: CityCreatePage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.CitiesCreate],
                },
            },
            {
                name: 'administration.cities.edit',
                path: 'cities/edit/:id',
                component: CityEditPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.CitiesUpdate],
                },
            },
            {
                name: 'administration.sections.edit',
                path: 'sections/edit/:id',
                component: SectionEditPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.SectionsUpdate],
                },
            },
            {
                name: 'administration.floors.edit',
                path: 'floors/edit/:id',
                component: FloorEditPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.FloorsUpdate],
                },
            },
            {
                name: 'administration.apartments.edit',
                path: 'apartments/edit/:id',
                component: ApartmentEditPage,
                meta: {
                    authName: oidc.authName,
                    permissions: [PermissionsEnum.ApartmentsUpdate],
                },
            },
            {
                name: 'administration.notifications',
                path: 'notifications',
                redirect: {
                    name: 'administration.designing',
                },
                meta: {
                    authName: oidc.authName,
                },
            },
            {
                name: 'administration.designing',
                path: 'designing',
                component: NotificationsPage,
                meta: {
                    authName: oidc.authName,
                },
            },
        ],
    },
];
